/*! ContentTools v1.3.1 by Anthony Blackshaw <ant@getme.co.uk> (https://github.com/anthonyjb) */
.ce--dragging,
.ce--resizing {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ce--dragging {
  cursor: move !important;
}
.ce--resizing {
  cursor: nwse-resize !important;
}
.ce-element--type-image,
.ce-element--type-video {
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;
  z-index: 1;
}
.ce-element--type-image:after,
.ce-element--type-image:before,
.ce-element--type-video:after,
.ce-element--type-video:before {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  color: #fff;
  display: none;
  font-family: arial, sans-serif;
  font-size: 10px;
  line-height: 10px;
  padding: 4px 4px 3px;
  position: absolute;
}
.ce-element--type-image:before,
.ce-element--type-video:before {
  content: attr(data-ce-size);
  right: 10px;
  top: 10px;
}
.ce-element--type-image.ce-element--over:before,
.ce-element--type-image.ce-element--resizing:before,
.ce-element--type-video.ce-element--over:before,
.ce-element--type-video.ce-element--resizing:before {
  display: block;
}
.ce-element--type-image {
  background-position: 0 0;
  background-size: cover;
}
.ce-element--type-image:after {
  background: transparent;
  content: '';
  display: block;
  left: 0;
  position: relative;
  top: 0;
  height: 100%;
  width: 100%;
}
.ce-element--type-video {
  background: #333 url(images/video.svg) 50% / auto 48px no-repeat;
}
.ce-element--type-video:after {
  bottom: 10px;
  content: attr(data-ce-title);
  display: block;
  left: 10px;
}
.ce-element--empty:after {
  content: '...';
  display: inline-block;
  font-style: italic;
  opacity: 0.5;
}
.ce-element--empty[data-ce-placeholder]:after {
  content: attr(data-ce-placeholder);
}
.ce-element--dragging {
  background-color: rgba(51, 51, 51, 0.1) !important;
  opacity: 0.5;
  z-index: -1;
}
.ce-element--dragging.ce-element--type-image,
.ce-element--dragging.ce-element--type-video {
  background-color: #333 !important;
  opacity: 1;
  outline-color: rgba(51, 51, 51, 0.1) !important;
}
.ce-element--drop {
  position: relative !important;
}
.ce-element--drop:before {
  background: #f39c12 url(images/drop-vert-above.svg) 50% / auto 32px repeat;
  bottom: 0;
  content: '' !important;
  left: 0;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
}
.ce-element--drop-below:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.ce-element--drop-left:before {
  background-image: url(images/drop-horz.svg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.ce-element--drop-right:before {
  background-image: url(images/drop-horz.svg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.ce-element--drop.ce-element--type-table-row {
  background: #f39c12 url(images/drop-vert-above.svg) 50% / auto 32px repeat;
}
.ce-element--drop.ce-element--type-table-row:before {
  display: none;
}
.ce-element--drop.ce-element--type-table-row.ce-element--drop-below {
  background: #f39c12 url(images/drop-vert-below.svg) 50% / auto 32px repeat;
}
.ce-element--focused,
.ce-element--over {
  background-color: rgba(243, 156, 18, 0.1);
  outline: none;
}
.ce-element--focused.ce-element--type-image,
.ce-element--focused.ce-element--type-video,
.ce-element--over.ce-element--type-image,
.ce-element--over.ce-element--type-video {
  background-color: #333;
  outline: 4px solid rgba(243, 156, 18, 0.35);
}
.ce-element--resize-top-left {
  cursor: nw-resize;
}
.ce-element--resize-top-right {
  cursor: ne-resize;
}
.ce-element--resize-bottom-right {
  cursor: se-resize;
}
.ce-element--resize-bottom-left {
  cursor: sw-resize;
}
.ce-drag-helper {
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
  color: #4e4e4e;
  font: arial, sans-serif;
  font-size: 12px;
  height: 120px;
  left: 0;
  line-height: 135%;
  margin: 5px 0 0 5px;
  overflow: hidden;
  padding: 15px;
  position: absolute;
  top: 0;
  width: 120px;
  word-wrap: break-word;
  z-index: 9;
}
.ce-drag-helper:before {
  background: #2980b9;
  color: #fff;
  content: attr(data-ce-type);
  display: block;
  font-family: arial, sans-serif;
  font-size: 10px;
  line-height: 10px;
  padding: 4px 4px 3px;
  position: absolute;
  right: 0;
  top: 0;
}
.ce-drag-helper--type-list-item-text:after,
.ce-drag-helper--type-list:after,
.ce-drag-helper--type-pre-text:after,
.ce-drag-helper--type-table-row:after,
.ce-drag-helper--type-table:after,
.ce-drag-helper--type-text:after {
  background-image: linear-gradient(hsla(0, 0%, 100%, 0), #fff 66%);
  bottom: 0;
  content: '';
  display: block;
  height: 40px;
  left: 0;
  position: absolute;
  width: 100%;
}
.ce-drag-helper--type-image {
  background-repeat: no-repeat;
  background-size: cover;
}
.ce-element--type-image,
.ce-element--type-video {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.ce-element--type-image.align-left,
.ce-element--type-video.align-left {
  clear: none;
  float: left;
}
.ce-element--type-image.align-right,
.ce-element--type-video.align-right {
  clear: none;
  float: right;
}
.ce-measure {
  display: block !important;
}
@font-face {
  font-family: icon;
  src: url(icons.woff);
  font-weight: 400;
  font-style: normal;
}
.ct-widget,
.ct-widget * {
  box-sizing: border-box;
}
.ct-widget * a,
.ct-widget * b,
.ct-widget * caption,
.ct-widget * div,
.ct-widget * form,
.ct-widget * i fieldset,
.ct-widget * iframe,
.ct-widget * label,
.ct-widget * legend,
.ct-widget * span,
.ct-widget * table,
.ct-widget * tbody,
.ct-widget * td,
.ct-widget * tfoot,
.ct-widget * th,
.ct-widget * thead,
.ct-widget * tr,
.ct-widget a,
.ct-widget b,
.ct-widget caption,
.ct-widget div,
.ct-widget form,
.ct-widget i fieldset,
.ct-widget iframe,
.ct-widget label,
.ct-widget legend,
.ct-widget span,
.ct-widget table,
.ct-widget tbody,
.ct-widget td,
.ct-widget tfoot,
.ct-widget th,
.ct-widget thead,
.ct-widget tr {
  border: 0;
  font-size: 100%;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}
.ct-widget * ol,
.ct-widget * ul,
.ct-widget ol,
.ct-widget ul {
  list-style: none;
}
.ct-widget * table,
.ct-widget table {
  border-collapse: collapse;
  border-spacing: 0;
}
.ct-widget {
  opacity: 0;
  font-family: arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 9999;
}
.ct-widget,
.ct-widget--active {
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.ct-widget--active {
  opacity: 1;
}
.ct-widget .ct-attribute {
  border-bottom: 1px solid #eee;
  height: 48px;
  vertical-align: top;
}
.ct-widget .ct-attribute:after {
  clear: both;
  content: '';
  display: table;
}
.ct-widget .ct-attribute__name {
  background: #f6f6f6;
  border: none;
  color: #646464;
  float: left;
  height: 47px;
  outline: none;
  padding: 0 16px;
  font-family: arial, sans-serif;
  font-size: 14px;
  line-height: 48px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 25%;
}
.ct-widget .ct-attribute__name--invalid {
  color: #e74c3c;
}
.ct-widget .ct-attribute__value {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  border: none;
  color: #646464;
  float: right;
  height: 47px;
  outline: none;
  padding: 0 16px;
  font-family: arial, sans-serif;
  font-size: 14px;
  line-height: 48px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 75%;
}
.ct-widget .ct-crop-marks {
  height: 320px;
  left: 73px;
  position: absolute;
  top: 0;
  width: 427px;
}
.ct-widget .ct-crop-marks__clipper {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.ct-widget .ct-crop-marks__ruler--top-left {
  position: absolute;
}
.ct-widget .ct-crop-marks__ruler--top-left:after {
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  border-bottom: none;
  border-right: none;
  box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.25),
    inset 1px 1px 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 999px;
  left: 0;
  position: absolute;
  top: 0;
  width: 999px;
}
.ct-widget .ct-crop-marks__ruler--bottom-right {
  position: absolute;
}
.ct-widget .ct-crop-marks__ruler--bottom-right:after {
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  border-top: none;
  border-left: none;
  bottom: 0;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25),
    inset -1px -1px 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 999px;
  position: absolute;
  right: 0;
  width: 999px;
}
.ct-widget .ct-crop-marks__handle {
  background: #2980b9;
  border: 1px solid #409ad5;
  border-radius: 7px;
  cursor: pointer;
  height: 15px;
  margin-left: -7px;
  margin-top: -7px;
  position: absolute;
  width: 15px;
}
.ct-widget .ct-crop-marks__handle--bottom-right {
  margin-left: -8px;
  margin-top: -8px;
}
.ct-widget .ct-crop-marks__handle:hover {
  background: #2e8ece;
}
@-webkit-keyframes a {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
    -webkit-transform: transform;
  }
  to {
    transform: translate(-50%, -50%) rotate(359deg);
    -webkit-transform: transform;
  }
}
@keyframes a {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
    -webkit-transform: transform;
    transform: transform;
  }
  to {
    transform: translate(-50%, -50%) rotate(359deg);
    -webkit-transform: transform;
    transform: transform;
  }
}
.ct-widget.ct-dialog {
  background: #fff;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.35);
  border-radius: 2px;
  height: 480px;
  left: 50%;
  margin-left: -350px;
  margin-top: -240px;
  position: fixed;
  top: 50%;
  width: 700px;
  z-index: 10099;
}
.ct-widget.ct-dialog--busy .ct-dialog__busy {
  display: block;
}
.ct-widget.ct-dialog--busy .ct-dialog__body {
  opacity: 0.1;
}
.ct-widget .ct-dialog__header {
  color: #a4a4a4;
  border-bottom: 1px solid #eee;
  height: 48px;
  padding: 0 16px;
  position: relative;
}
.ct-widget .ct-dialog__caption {
  font-family: arial, sans-serif;
  font-size: 18px;
}
.ct-widget .ct-dialog__caption,
.ct-widget .ct-dialog__close {
  line-height: 48px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ct-widget .ct-dialog__close {
  border-left: 1px solid #eee;
  cursor: pointer;
  height: 48px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  font-family: icon;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  speak: none;
  text-transform: none;
  width: 48px;
}
.ct-widget .ct-dialog__close:before {
  content: '\ea0f';
}
.ct-widget .ct-dialog__close:hover:before {
  color: #646464;
}
.ct-widget .ct-dialog__body {
  margin: auto;
  width: 572px;
}
.ct-widget .ct-dialog__view {
  height: 320px;
  margin-top: 32px;
}
.ct-widget .ct-dialog__controls {
  margin-top: 16px;
}
.ct-widget .ct-dialog__controls:after {
  clear: both;
  content: '';
  display: table;
}
.ct-widget .ct-dialog__busy {
  display: none;
  position: absolute;
}
.ct-widget .ct-dialog__busy:before {
  -webkit-animation: a 5s linear;
  animation: a 5s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  color: #a4a4a4;
  content: '\e994';
  left: 50%;
  position: fixed;
  top: 50%;
  font-family: icon;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ct-widget .ct-control-group {
  font-size: 0;
}
.ct-widget .ct-control-group--center {
  text-align: center;
}
.ct-widget .ct-control-group--left {
  float: left;
}
.ct-widget .ct-control-group--right {
  float: right;
}
.ct-widget .ct-control {
  margin-left: 16px;
  position: relative;
}
.ct-widget .ct-control:first-child {
  margin-left: 0;
}
.ct-widget .ct-control--icon {
  color: #a4a4a4;
  cursor: pointer;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-family: icon;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  speak: none;
  text-transform: none;
  width: 32px;
}
.ct-widget .ct-control--icon,
.ct-widget .ct-control--icon:after {
  border-radius: 2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ct-widget .ct-control--icon:after {
  background: #000;
  color: #fff;
  content: attr(data-ct-tooltip);
  display: block;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  left: -26.5px;
  opacity: 0;
  padding: 0 8px;
  position: absolute;
  bottom: 37px;
  font-family: arial, sans-serif;
  font-size: 12px;
  line-height: 20px;
  visibility: hidden;
  width: 85px;
  word-break: break-word;
}
.ct-widget .ct-control--icon:hover:after {
  opacity: 0.8;
  visibility: visible;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  -webkit-transition-delay: 2s;
  transition-delay: 2s;
}
.ct-widget .ct-control--icon:before {
  content: '';
}
.ct-widget .ct-control--icon:hover {
  background: #eee;
  color: #646464;
}
.ct-widget .ct-control--active,
.ct-widget .ct-control--on {
  background: #a4a4a4;
  color: #fff;
}
.ct-widget .ct-control--active:hover,
.ct-widget .ct-control--on:hover {
  background: #646464;
  color: #fff;
}
.ct-widget .ct-control--rotate-ccw:before {
  content: '\e965';
}
.ct-widget .ct-control--rotate-cw:before {
  content: '\e966';
}
.ct-widget .ct-control--crop:before {
  content: '\ea57';
}
.ct-widget .ct-control--remove:before {
  content: '\e9ac';
}
.ct-widget .ct-control--styles:before {
  content: '\e90b';
}
.ct-widget .ct-control--attributes:before {
  content: '\e994';
}
.ct-widget .ct-control--code:before {
  content: '\ea80';
}
.ct-widget .ct-control--icon.ct-control--muted {
  cursor: default;
}
.ct-widget .ct-control--icon.ct-control--muted:before {
  opacity: 0.5;
}
.ct-widget .ct-control--icon.ct-control--muted:hover {
  color: #a4a4a4;
  background: transparent;
}
.ct-widget .ct-control--text {
  background: #2980b9;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  height: 32px;
  overflow: hidden;
  padding: 0 8px;
  text-align: center;
  text-overflow: ellipsis;
  font-family: arial, sans-serif;
  font-size: 14px;
  line-height: 32px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: top;
  width: 100px;
}
.ct-widget .ct-control--text:hover {
  background: #2e8ece;
}
.ct-widget .ct-control--apply,
.ct-widget .ct-control--insert,
.ct-widget .ct-control--ok {
  background: #27ae60;
}
.ct-widget .ct-control--apply:hover,
.ct-widget .ct-control--insert:hover,
.ct-widget .ct-control--ok:hover {
  background: #2cc36b;
}
.ct-widget .ct-control--cancel,
.ct-widget .ct-control--clear {
  background: #e74c3c;
}
.ct-widget .ct-control--cancel:hover,
.ct-widget .ct-control--clear:hover {
  background: #ea6153;
}
.ct-widget .ct-control--text.ct-control--muted {
  background: #ccc;
  cursor: default;
}
.ct-widget .ct-control--text.ct-control--muted:hover {
  background: #ccc;
}
.ct-widget .ct-control--upload {
  overflow: hidden;
}
.ct-widget.ct-image-dialog--empty .ct-control--cancel,
.ct-widget.ct-image-dialog--empty .ct-control--clear,
.ct-widget.ct-image-dialog--empty .ct-control--crop,
.ct-widget.ct-image-dialog--empty .ct-control--insert,
.ct-widget.ct-image-dialog--empty .ct-control--rotate-ccw,
.ct-widget.ct-image-dialog--empty .ct-control--rotate-cw,
.ct-widget.ct-image-dialog--empty .ct-progress-bar,
.ct-widget.ct-image-dialog--populated .ct-control--cancel,
.ct-widget.ct-image-dialog--populated .ct-control--upload,
.ct-widget.ct-image-dialog--populated .ct-progress-bar,
.ct-widget.ct-image-dialog--uploading .ct-control--clear,
.ct-widget.ct-image-dialog--uploading .ct-control--crop,
.ct-widget.ct-image-dialog--uploading .ct-control--insert,
.ct-widget.ct-image-dialog--uploading .ct-control--rotate-ccw,
.ct-widget.ct-image-dialog--uploading .ct-control--rotate-cw,
.ct-widget.ct-image-dialog--uploading .ct-control--upload {
  display: none;
}
.ct-widget .ct-image-dialog__view {
  background: #eee;
  position: relative;
}
.ct-widget .ct-image-dialog__view:empty {
  font-family: icon;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 320px;
  text-align: center;
}
.ct-widget .ct-image-dialog__view:empty:before {
  color: #fff;
  content: '\e90d';
}
.ct-widget .ct-image-dialog__image {
  background-color: transparent;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
}
.ct-widget .ct-image-dialog__file-upload {
  cursor: pointer;
  font-size: 400px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}
.ct-widget.ct-properties-dialog--attributes .ct-properties-dialog__attributes,
.ct-widget.ct-properties-dialog--styles .ct-properties-dialog__styles {
  display: block;
}
.ct-widget.ct-properties-dialog--styles
  .ct-properties-dialog__styles:empty:before {
  color: #a4a4a4;
  content: attr(data-ct-empty);
  display: block;
  font-style: italic;
  margin-top: 20px;
  text-align: center;
}
.ct-widget.ct-properties-dialog--code .ct-properties-dialog__code {
  display: block;
}
.ct-widget .ct-properties-dialog__view {
  border: 1px solid #ddd;
  overflow: auto;
}
.ct-widget .ct-properties-dialog__attributes,
.ct-widget .ct-properties-dialog__code,
.ct-widget .ct-properties-dialog__styles {
  display: none;
}
.ct-widget .ct-properties-dialog__inner-html {
  border: none;
  display: block;
  font-family: courier, Bitstream Vera Sans Mono, Consolas, Courier, monospace;
  height: 318px;
  padding: 16px;
  outline: none;
  resize: none;
  width: 100%;
}
.ct-widget .ct-properties-dialog__inner-html--invalid {
  color: #e74c3c;
}
.ct-widget .ct-table-dialog__view {
  border: 1px solid #ddd;
  overflow: auto;
}
.ct-widget .ct-video-dialog__preview:empty {
  background: #eee;
  font-family: icon;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 320px;
  text-align: center;
}
.ct-widget .ct-video-dialog__preview:empty:before {
  color: #fff;
  content: '\ea98';
}
.ct-widget .ct-video-dialog__input {
  border: none;
  border-bottom: 1px solid #eee;
  height: 32px;
  line-height: 32px;
  outline: none;
  padding: 0 4px;
  font-family: arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: top;
  width: 456px;
}
.ct-widget .ct-video-dialog__input:focus {
  border-bottom: 1px solid #e1e1e1;
}
.ct-widget.ct-anchored-dialog {
  border-bottom: 2px solid #27ae60;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.35);
  font-size: 0;
  height: 34px;
  left: 0;
  margin-left: -160px;
  margin-top: -48px;
  position: absolute;
  top: 0;
  width: 320px;
  z-index: 10099;
}
.ct-widget.ct-anchored-dialog:after {
  border: 16px solid hsla(0, 0%, 100%, 0);
  border-top-color: #27ae60;
  content: '';
  left: 144px;
  position: absolute;
  top: 34px;
}
.ct-widget .ct-anchored-dialog__input {
  border: none;
  color: #646464;
  outline: none;
  font-family: arial, sans-serif;
  font-size: 14px;
  padding: 0 8px 0 16px;
  vertical-align: top;
  width: 256px;
}
.ct-widget .ct-anchored-dialog__button,
.ct-widget .ct-anchored-dialog__input {
  height: 32px;
  line-height: 32px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ct-widget .ct-anchored-dialog__button {
  background: #27ae60;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  font-family: icon;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  speak: none;
  text-transform: none;
  width: 32px;
}
.ct-widget .ct-anchored-dialog__button:before {
  color: #fff;
  content: '\ea10';
}
.ct-widget .ct-anchored-dialog__button:hover {
  background: #2cc36b;
}
.ct-widget .ct-anchored-dialog__target-button {
  background: #fff;
  cursor: pointer;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-family: icon;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 32px;
}
.ct-widget .ct-anchored-dialog__target-button:before {
  color: #a4a4a4;
  content: '\ea7d';
}
.ct-widget .ct-anchored-dialog__target-button:hover:before {
  color: #b1b1b1;
}
.ct-widget .ct-anchored-dialog__target-button--active:before {
  color: #27ae60;
}
.ct-widget .ct-anchored-dialog__target-button--active:hover:before {
  color: #2cc36b;
}
@-webkit-keyframes b {
  0% {
    opacity: 0;
    font-size: 32px;
    -webkit-transform: font-size;
  }
  25% {
    font-size: 320px;
    opacity: 1;
    -webkit-transform: all;
  }
  50% {
    font-size: 320px;
    opacity: 1;
    -webkit-transform: all;
  }
  75% {
    font-size: 320px;
    opacity: 1;
    -webkit-transform: all;
  }
  to {
    opacity: 0;
    -webkit-transform: all;
  }
}
@keyframes b {
  0% {
    opacity: 0;
    font-size: 32px;
    -webkit-transform: font-size;
    transform: font-size;
  }
  25% {
    font-size: 320px;
    opacity: 1;
    -webkit-transform: all;
    transform: all;
  }
  50% {
    font-size: 320px;
    opacity: 1;
    -webkit-transform: all;
    transform: all;
  }
  75% {
    font-size: 320px;
    opacity: 1;
    -webkit-transform: all;
    transform: all;
  }
  to {
    opacity: 0;
    -webkit-transform: all;
    transform: all;
  }
}
@-webkit-keyframes c {
  0% {
    opacity: 1;
    -webkit-transform: opacity;
  }
  99% {
    opacity: 1;
    -webkit-transform: opacity;
  }
  to {
    opacity: 0;
    -webkit-transform: opacity;
  }
}
@keyframes c {
  0% {
    opacity: 1;
    -webkit-transform: opacity;
    transform: opacity;
  }
  99% {
    opacity: 1;
    -webkit-transform: opacity;
    transform: opacity;
  }
  to {
    opacity: 0;
    -webkit-transform: opacity;
    transform: opacity;
  }
}
.ct-widget.ct-flash {
  color: hsla(0, 0%, 100%, 0.9);
  height: 0;
  left: 0;
  position: fixed;
  font-family: icon;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 0;
  width: 0;
  z-index: 10999;
}
.ct-widget.ct-flash:before {
  left: 50%;
  opacity: 0;
  position: fixed;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  top: 50%;
  transform: translate(-50%, -50%);
}
.ct-widget.ct-flash--active {
  -webkit-animation: c 2s ease-in;
  animation: c 2s ease-in;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.ct-widget.ct-flash--active:before {
  -webkit-animation: b 2s ease-in;
  animation: b 2s ease-in;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  font-size: 320px;
  opacity: 1;
}
.ct-widget.ct-flash--ok:before {
  content: '\ea10';
}
.ct-widget.ct-flash--no:before {
  content: '\ea0f';
}
.ct-widget .ct-grip {
  cursor: move;
  font-size: 0;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ct-widget .ct-grip__bump {
  background: rgba(70, 70, 70, 0.15);
  border-radius: 12px;
  display: inline-block;
  height: 12px;
  margin-left: 12px;
  width: 12px;
}
.ct-widget .ct-grip__bump:first-child {
  margin-left: 0;
}
@-webkit-keyframes d {
  0% {
    transform: rotate(0deg);
    -webkit-transform: transform;
  }
  to {
    transform: rotate(359deg);
    -webkit-transform: transform;
  }
}
@keyframes d {
  0% {
    transform: rotate(0deg);
    -webkit-transform: transform;
    transform: transform;
  }
  to {
    transform: rotate(359deg);
    -webkit-transform: transform;
    transform: transform;
  }
}
.ct-widget.ct-ignition {
  left: 16px;
  position: fixed;
  top: 16px;
}
.ct-widget.ct-ignition .ct-ignition__button {
  display: none;
}
.ct-widget.ct-ignition--editing .ct-ignition__button--cancel,
.ct-widget.ct-ignition--editing .ct-ignition__button--confirm,
.ct-widget.ct-ignition--ready .ct-ignition__button--edit {
  display: block;
}
.ct-widget.ct-ignition--busy .ct-ignition__button {
  display: none;
}
.ct-widget.ct-ignition--busy .ct-ignition__button--busy {
  display: block;
}
.ct-widget .ct-ignition__button {
  border-radius: 24px;
  content: '';
  cursor: pointer;
  display: block;
  height: 48px;
  line-height: 48px;
  opacity: 0.9;
  position: absolute;
  text-align: center;
  font-family: icon;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 48px;
}
.ct-widget .ct-ignition__button:before {
  color: #fff;
}
.ct-widget .ct-ignition__button--busy {
  -webkit-animation: d 5s linear;
  animation: d 5s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  background: #646464;
  cursor: default;
}
.ct-widget .ct-ignition__button--busy:before {
  content: '\e994';
}
.ct-widget .ct-ignition__button--busy:hover {
  background: #646464;
}
.ct-widget .ct-ignition__button--confirm {
  background: #27ae60;
}
.ct-widget .ct-ignition__button--confirm:before {
  content: '\ea10';
}
.ct-widget .ct-ignition__button--confirm:hover {
  background: #2cc36b;
}
.ct-widget .ct-ignition__button--cancel {
  background: #e74c3c;
  left: 64px;
}
.ct-widget .ct-ignition__button--cancel:before {
  content: '\ea0f';
}
.ct-widget .ct-ignition__button--cancel:hover {
  background: #ea6153;
}
.ct-widget .ct-ignition__button--edit {
  background: #2980b9;
}
.ct-widget .ct-ignition__button--edit:before {
  content: '\e905';
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.ct-widget .ct-ignition__button--edit:hover {
  background: #2e8ece;
}
.ct-widget .ct-ignition__button--edit:hover:before {
  display: inline-block;
  -webkit-transform: rotate(-15deg);
  transform: rotate(-15deg);
}
.ct-widget.ct-inspector {
  background: hsla(0, 0%, 91%, 0.2);
  border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  bottom: 0;
  height: 32px;
  left: 0;
  overflow: hidden;
  padding: 3px 16px 0;
  position: fixed;
  width: 100%;
}
.ct-widget .ct-inspector__tags {
  width: calc(100% - 128px);
}
.ct-widget .ct-inspector__tags:after {
  clear: both;
  content: '';
  display: table;
}
.ct-widget .ct-inspector__tags:before {
  color: #464646;
  content: '\ea80';
  display: block;
  float: left;
  height: 24px;
  line-height: 24px;
  margin-right: 16px;
  text-align: center;
  font-family: icon;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 24px;
}
.ct-widget .ct-inspector__counter {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  height: 24px;
  line-height: 24px;
  margin-right: 16px;
  position: absolute;
  right: 0;
  text-align: right;
  top: 3px;
  width: 128px;
}
.ct-widget .ct-tag {
  background-color: #2980b9;
  border-radius: 2px 0 0 2px;
  color: #fff;
  cursor: pointer;
  float: left;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  margin-left: 24px;
  padding: 0 8px;
  position: relative;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.35);
}
.ct-widget .ct-tag:after {
  border-style: solid;
  border-bottom: 12px solid rgba(255, 0, 0, 0);
  border-left: 12px solid #2980b9;
  border-right: none;
  border-top: 12px solid rgba(255, 0, 0, 0);
  content: '';
  display: block;
  height: 24px;
  bottom: 0;
  right: -24px;
  position: absolute;
  width: 24px;
  -moz-transform: scale(0.9999);
}
.ct-widget .ct-tag:first-child {
  margin-left: 0;
}
.ct-widget .ct-tag:hover {
  background-color: #4aa3df;
}
.ct-widget .ct-tag:hover:after {
  border-left-color: #4aa3df;
}
.ct-widget .ct-tag:nth-child(1) {
  background-color: #8e44ad;
}
.ct-widget .ct-tag:nth-child(1):after {
  border-left-color: #8e44ad;
}
.ct-widget .ct-tag:nth-child(1):hover {
  background-color: #9b50ba;
}
.ct-widget .ct-tag:nth-child(1):hover:after {
  border-left-color: #9b50ba;
}
.ct-widget .ct-tag:nth-child(2) {
  background-color: #2980b9;
}
.ct-widget .ct-tag:nth-child(2):after {
  border-left-color: #2980b9;
}
.ct-widget .ct-tag:nth-child(2):hover {
  background-color: #2e8ece;
}
.ct-widget .ct-tag:nth-child(2):hover:after {
  border-left-color: #2e8ece;
}
.ct-widget .ct-tag:nth-child(3) {
  background-color: #27ae60;
}
.ct-widget .ct-tag:nth-child(3):after {
  border-left-color: #27ae60;
}
.ct-widget .ct-tag:nth-child(3):hover {
  background-color: #2cc36b;
}
.ct-widget .ct-tag:nth-child(3):hover:after {
  border-left-color: #2cc36b;
}
.ct-widget .ct-tag:nth-child(4) {
  background-color: #d35400;
}
.ct-widget .ct-tag:nth-child(4):after {
  border-left-color: #d35400;
}
.ct-widget .ct-tag:nth-child(4):hover {
  background-color: #ed5e00;
}
.ct-widget .ct-tag:nth-child(4):hover:after {
  border-left-color: #ed5e00;
}
.ct-widget .ct-tag:nth-child(5) {
  background-color: #f39c12;
}
.ct-widget .ct-tag:nth-child(5):after {
  border-left-color: #f39c12;
}
.ct-widget .ct-tag:nth-child(5):hover {
  background-color: #f4a62a;
}
.ct-widget .ct-tag:nth-child(5):hover:after {
  border-left-color: #f4a62a;
}
.ct-widget .ct-tag:nth-child(6) {
  background-color: #16a085;
}
.ct-widget .ct-tag:nth-child(6):after {
  border-left-color: #16a085;
}
.ct-widget .ct-tag:nth-child(6):hover {
  background-color: #19b698;
}
.ct-widget .ct-tag:nth-child(6):hover:after {
  border-left-color: #19b698;
}
.ct-widget.ct-modal {
  background: rgba(0, 0, 0, 0.7);
  height: 0;
  left: 0;
  position: fixed;
  top: 0;
  width: 0;
  z-index: 10009;
}
.ct-widget.ct-modal--transparent {
  background: transparent;
}
.ct-widget--active.ct-modal {
  height: 100%;
  width: 100%;
}
.ct-widget .ct-progress-bar {
  border: 1px solid #eee;
  height: 32px;
  line-height: 32px;
  padding: 1px;
  width: 456px;
}
.ct-widget .ct-progress-bar__progress {
  background: #2980b9;
  height: 28px;
}
.ct-widget .ct-section {
  border-bottom: 1px solid #eee;
  color: #bdbdbd;
  cursor: pointer;
  font-style: italic;
  height: 48px;
  padding: 0 16px;
  font-family: arial, sans-serif;
  font-size: 16px;
  line-height: 48px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ct-widget .ct-section:after {
  clear: both;
  content: '';
  display: table;
}
.ct-widget .ct-section:hover {
  background: #f6f6f6;
}
.ct-widget .ct-section--applied {
  color: #646464;
  font-style: normal;
}
.ct-widget .ct-section--applied .ct-section__switch {
  background-color: #27ae60;
  border: 1px solid #1e8449;
}
.ct-widget .ct-section--applied .ct-section__switch:before {
  left: 25px;
  -webkit-transition-property: left;
  transition-property: left;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.ct-widget .ct-section--contains-input .ct-section__label {
  width: 75%;
}
.ct-widget .ct-section__label {
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 472px;
  white-space: nowrap;
}
.ct-widget .ct-section__switch {
  background-color: #ccc;
  border: 1px solid #b3b3b3;
  border-radius: 12px;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  float: right;
  height: 24px;
  margin-top: 12px;
  position: relative;
  width: 48px;
}
.ct-widget .ct-section__switch:before {
  background: #fff;
  border-radius: 10px;
  content: '';
  height: 20px;
  left: 1px;
  position: absolute;
  top: 1px;
  -webkit-transition-property: left;
  transition-property: left;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  width: 20px;
}
.ct-widget .ct-section__input {
  background: #fff;
  border: none;
  color: #646464;
  float: right;
  height: 47px;
  outline: none;
  padding: 0 16px;
  text-align: right;
  font-family: arial, sans-serif;
  font-size: 14px;
  line-height: 48px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 25%;
}
.ct-widget .ct-section__input--invalid {
  color: #e74c3c;
}
.ct-widget.ct-toolbox {
  background: hsla(0, 0%, 91%, 0.9);
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.35);
  left: 128px;
  padding: 8px;
  position: fixed;
  top: 128px;
  width: 138px;
}
.ct-widget.ct-toolbox--dragging {
  opacity: 0.5;
}
.ct-widget .ct-toolbox__grip {
  padding: 8px 0;
}
.ct-widget .ct-tool-group {
  padding: 4px 0;
}
.ct-widget .ct-tool-group:after {
  clear: both;
  content: '';
  display: table;
}
.ct-widget .ct-tool-group:first-child {
  padding-top: 0;
}
.ct-widget .ct-tool {
  color: #464646;
  cursor: pointer;
  float: left;
  height: 32px;
  margin: 4px;
  margin-right: 4px;
  position: relative;
  text-align: center;
  font-family: icon;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  speak: none;
  text-transform: none;
  width: 32px;
}
.ct-widget .ct-tool,
.ct-widget .ct-tool:after {
  border-radius: 2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ct-widget .ct-tool:after {
  background: #000;
  color: #fff;
  content: attr(data-ct-tooltip);
  display: block;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  left: -26.5px;
  opacity: 0;
  padding: 0 8px;
  position: absolute;
  bottom: 37px;
  font-family: arial, sans-serif;
  font-size: 12px;
  line-height: 20px;
  visibility: hidden;
  width: 85px;
  word-break: break-word;
}
.ct-widget .ct-tool:hover:after {
  opacity: 0.8;
  visibility: visible;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  -webkit-transition-delay: 2s;
  transition-delay: 2s;
}
.ct-widget .ct-tool:before {
  line-height: 32px;
}
.ct-widget .ct-tool:nth-child(3n) {
  margin-right: 0;
}
.ct-widget .ct-tool:hover {
  background: hsla(0, 0%, 100%, 0.5);
}
.ct-widget .ct-tool--disabled {
  color: rgba(70, 70, 70, 0.33);
}
.ct-widget .ct-tool--disabled:hover {
  background: transparent;
}
.ct-widget .ct-tool--down {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25);
  line-height: 34px;
}
.ct-widget .ct-tool--down,
.ct-widget .ct-tool--down:hover {
  background: rgba(0, 0, 0, 0.025);
}
.ct-widget .ct-tool--applied {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25);
}
.ct-widget .ct-tool--applied:hover {
  background: rgba(0, 0, 0, 0.15);
}
.ct-widget .ct-tool--bold:before {
  content: '\ea62';
}
.ct-widget .ct-tool--heading:before {
  content: 'H';
  font-weight: 700;
}
.ct-widget .ct-tool--subheading:before {
  content: 'H';
}
.ct-widget .ct-tool--paragraph:before {
  content: 'P';
}
.ct-widget .ct-tool--preformatted:before {
  content: '\ea80';
}
.ct-widget .ct-tool--italic:before {
  content: '\ea64';
}
.ct-widget .ct-tool--link:before {
  content: '\e9cb';
}
.ct-widget .ct-tool--align-left:before {
  content: '\ea77';
}
.ct-widget .ct-tool--align-center:before {
  content: '\ea78';
}
.ct-widget .ct-tool--align-right:before {
  content: '\ea79';
}
.ct-widget .ct-tool--unordered-list:before {
  content: '\e9ba';
}
.ct-widget .ct-tool--ordered-list:before {
  content: '\e9b9';
}
.ct-widget .ct-tool--table:before {
  content: '\ea71';
}
.ct-widget .ct-tool--indent:before {
  content: '\ea7b';
}
.ct-widget .ct-tool--unindent:before {
  content: '\ea7c';
}
.ct-widget .ct-tool--line-break:before {
  content: '\ea6e';
}
.ct-widget .ct-tool--image:before {
  content: '\e90d';
}
.ct-widget .ct-tool--video:before {
  content: '\ea98';
}
.ct-widget .ct-tool--undo:before {
  content: '\e965';
}
.ct-widget .ct-tool--redo:before {
  content: '\e966';
}
.ct-widget .ct-tool--remove:before {
  content: '\e9ac';
}
@-webkit-keyframes e {
  0% {
    outline-color: hsla(0, 0%, 100%, 0);
    -webkit-transform: background-color;
  }
  25% {
    outline-color: #f39c12;
    -webkit-transform: background-color;
  }
  50% {
    outline-color: #f39c12;
    -webkit-transform: background-color;
  }
  to {
    outline-color: hsla(0, 0%, 100%, 0);
    -webkit-transform: background-color;
  }
}
@keyframes e {
  0% {
    outline-color: hsla(0, 0%, 100%, 0);
    -webkit-transform: background-color;
    transform: background-color;
  }
  25% {
    outline-color: #f39c12;
    -webkit-transform: background-color;
    transform: background-color;
  }
  50% {
    outline-color: #f39c12;
    -webkit-transform: background-color;
    transform: background-color;
  }
  to {
    outline-color: hsla(0, 0%, 100%, 0);
    -webkit-transform: background-color;
    transform: background-color;
  }
}
.ct-app,
.ct-app *,
.ct-app :after,
.ct-app :before {
  box-sizing: border-box;
}
.ct--highlight {
  outline: 4px solid #f39c12;
  -webkit-animation: e 0.5s ease-in;
  animation: e 0.5s ease-in;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.ct--no-scroll {
  overflow: hidden;
}
.ct--puesdo-select {
  background: rgba(0, 0, 0, 0.1);
}
