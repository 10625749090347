.share-report {
  position: absolute;

  right: 0;
  top: 50px;

  z-index: 100000;
}

.share-report::before {
  content: ' ';
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.35);
  height: 100%;
  width: 100%;
}

.share-report--content {
  position: absolute;

  top: 0;
  right: 0;
  width: 640px;

  background: #fff;

  padding: 15px 25px;
}

.share-report--content .row {
  margin-top: 15px;
  margin-bottom: 15px;
}

.share-report--content .row h3 {
  margin: 10px;
}

.share-report--content .row p {
  margin: 5px 10px 15px 10px;

  font-size: 14px;
}

.share-report--content .share-report--message {
  display: block;
  height: 120px;
  width: 100%;
  padding: 10px;
}

.share-report--email-to--user {
  display: inline-block;

  vertical-align: middle;

  font-size: 18px;

  margin: 5px;
}

.share-report--email-to--user-icon {
  display: inline-block;

  vertical-align: middle;

  background-size: cover;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;

  height: 18px;
  width: 18px;
  margin-right: 10px;
}

.btn-group .inactive,
.btn.inactive,
.share-report--content .inactive {
  -webkit-opacity: 0.67;
  -moz-opacity: 0.67;
  -ms-opacity: 0.67;
  -o-opacity: 0.67;
  opacity: 0.67;

  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  -ms-pointer-events: none;
  -o-pointer-events: none;
  pointer-events: none;
}

.share-report--content .Select-clear-zone,
.share-report--content .Select-arrow-zone {
  display: none;
}
.share-report--content .Select-value:not(:hover) > .Select-value-icon {
  visibility: hidden;
}

/*

.share-report--content .Select--multi .Select-value {
  background: transparent;
  color: #232323;

  border-color: transparent;
}

.share-report--content .Select--multi .Select-value:not(:hover) .Select-value-icon {
  border-color: transparent;

  -webkit-opacity: 0;
  -moz-opacity: 0;
  -ms-opacity: 0;
  -o-opacity: 0;
  opacity: 0;
}

.share-report--content .Select--multi .Select-input input {
  margin-top: 5px;
  font-size: 18px;

  max-width: 300px;
}

*/

.share-report--content .react-datepicker-wrapper input {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;

  border: 1px solid #c0c0c0;

  line-height: 1.25;

  padding: 6px 15px;
}

.report-comment-container > div.report-shared--top-info {
  position: absolute;
  top: 10px;
  left: 50%;

  padding: 15px 25px;

  width: 100%;
  max-width: 90%;

  text-align: right;

  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);

  font-size: 14px;

  background: transparent;
}

.report-comment-container > div.report-shared--top-info > div {
  display: inline-block;
  text-align: left;
  width: 100%;
  max-width: 360px;
}

.report-comment-container > div.report-shared--top-info > div > b {
  display: inline-block;
  min-width: 120px;
  text-align: right;
  padding-right: 5px;
}

.share-close-preview {
  position: fixed;
  top: 5px;
  right: 5px;
}

.report-item--share-container-wrapper div.report-shared--top-info {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 360px;
  text-align: left;
}

.report-item--share-container-wrapper div.report-shared--top-info > div > b {
  display: inline-block;
  min-width: 120px;
  text-align: right;
  padding-right: 5px;
}

.errCtrl {
  background-color: rgba(255, 0, 0, 0.3);
  border: 1ps solid red;
}

.errLbl {
  color: red;
}
