.reports-dashboard .existing-reports {
  max-height: 125px;
  overflow: auto;
  padding: 5px;
}

.reports-dashboard .existing-reports li.inactive {
  -webkit-opacity: 0.6;
  -moz-opacity: 0.6;
  -ms-opacity: 0.6;
  -o-opacity: 0.6;
  opacity: 0.6;

  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  -ms-pointer-events: none;
  -o-pointer-events: none;
  pointer-events: none;

  position: relative;
}

.reports-dashboard .report-item--content [data-name='content'] {
  text-align: left;
}

.reports-dashboard .signature-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: none;

  z-index: 100000;
}

.reports-dashboard .signature-popup .signature-popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}

.reports-dashboard .signature-popup .signature-popup-box {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;

  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  width: auto;
  height: auto;

  padding: 25px 15px 15px 15px;

  background: #fff;

  text-align: left;

  font-size: 12px;

  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;

  -webkit-box-shadow: 0 0 5px #c3c3c3;
  box-shadow: 0 0 5px #c3c3c3;
}

.reports-dashboard .signature-popup .signature-popup-box canvas {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  margin: 15px 0;

  border: 2px solid #c0c0c0;
}

.ct-inspector {
  display: none;
}

body > .ct-app > .ct-toolbox {
  display: none;
}

.reports-dashboard .reports-toolbox-container {
  min-height: 55px;
  margin-top: 36px;
}

body > .ct-app > .ct-widget.ct-ignition > .ct-ignition__button {
  display: none;
}

.reports-dashboard .reports-toolbox-container .ct-toolbox {
  position: static;
  width: auto;

  text-align: left;

  padding: 0 15px;
}

.reports-dashboard .reports-toolbox-container .ct-toolbox .ct-grip {
  display: none;
}

.reports-dashboard
  .reports-toolbox-container
  .ct-toolbox
  .ct-tool-groups
  .ct-tool-group {
  display: inline-block;
}

.reports-dashboard .ct-tool.ct-tool--line-break,
.reports-dashboard .ct-tool.ct-tool--image,
.reports-dashboard .ct-tool.ct-tool--video,
.reports-dashboard .ct-tool.ct-tool--preformatted {
  display: none;
}

.reports-dashboard .reports-top-buttons {
  text-align: left;
  position: relative;

  padding-bottom: 10px;
}

.reports-dashboard .reports-top-buttons + .reports-top-buttons {
  margin-top: 45px;
}

.reports-dashboard .reports-top-buttons .action-edit {
  float: right;
}

.reports-dashboard .reports-top-buttons .action-launch-ras {
  padding: 10px 15px;
  font-size: 17px;

  background: #286090 !important;
  color: #fff !important;
}

.reports-dashboard .reports-top-buttons .action-share .dropdown-menu {
  left: initial;
  right: 0;
}

.reports-dashboard .reports-top-buttons .action-share .dropdown-menu::before {
  left: initial;
  right: 27px;
}

.reports-dashboard .reports-top-buttons .action-share .dropdown-menu::after {
  left: initial;
  right: 28px;
  border-top-color: #286090;
  border-bottom-color: #286090;
}

.reports-dashboard .reports-top-buttons .action-share .dropdown-menu li {
  position: relative;
  line-height: 45px;

  padding: 0 27px;

  width: 169px;

  font-weight: bold;
  color: #fff;

  border-bottom: 1px solid #fff;

  background: #286090;

  cursor: pointer;
}

.reports-dashboard
  .reports-top-buttons
  .action-share
  .dropdown-menu
  li.action-share--grantee {
  color: #c0c0c0;
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  -ms-pointer-events: none;
  -o-pointer-events: none;
  pointer-events: none;
  cursor: not-allowed;
}

[report-state='signed'].reports-dashboard
  .reports-top-buttons
  .action-share
  .dropdown-menu
  li.action-share--grantee {
  color: #fff;

  cursor: pointer;

  -webkit-pointer-events: all;
  -moz-pointer-events: all;
  -ms-pointer-events: all;
  -o-pointer-events: all;
  pointer-events: all;
}

.reports-dashboard
  .reports-top-buttons
  .action-share
  .dropdown-menu
  li.action-share--grantee
  i {
  position: absolute;

  font-weight: normal;
  font-size: 10px;

  top: 12px;
  left: 0;

  width: 100%;

  text-align: center;
}

[report-state='signed'].reports-dashboard
  .reports-top-buttons
  .action-share
  .dropdown-menu
  li.action-share--grantee
  i {
  display: none;
}

.reports-dashboard .reports-top-right-buttons {
  position: absolute;
  top: 0;
  right: 0;
}

.reports-dashboard .reports-top-buttons .btn-primary {
  margin-left: 3px;
  margin-right: 3px;
}

.reports-dashboard .reports-top-buttons .btn-primary:not(.active) {
  background: transparent;
  color: #454545;
}

.reports-dashboard .reports-top-buttons .btn-primary:hover,
.reports-dashboard .reports-top-buttons .btn-primary:focus {
  color: #fff;
  background: #286090;
}

.reports-dashboard .reports-top-buttons .active-inline-chats--toggle-comments {
  position: absolute;
  right: 0;
  top: -50px;
}

input.tgl-bar {
  position: fixed;
  pointer-events: none;
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  -ms-pointer-events: none;
  -o-pointer-events: none;

  -webkit-outline: none;
  -moz-outline: none;
  -ms-outline: none;
  outline: none;

  -webkit-opacity: 0;
  -moz-opacity: 0;
  -ms-opacity: 0;
  -o-opacity: 0;
  opacity: 0;
}
input.tgl-bar + label {
  white-space: nowrap;

  padding-right: 57px;
  margin-right: 5px;

  position: relative;

  display: inline-block;

  vertical-align: top;

  cursor: pointer;
}

input.tgl-bar + label::after {
  content: '✕';
  position: absolute;

  right: 0;
  top: 0;

  background: #606060;

  color: #fff;

  display: block;

  width: 50px;
  height: 24px;

  font-size: 18px;
  line-height: 24px;

  text-align: right;

  -webkit-transition: 0.5s background;
  -moz-transition: 0.5s background;
  -ms-transition: 0.5s background;
  -o-transition: 0.5s background;
  transition: 0.5s background;

  padding: 0 6px;

  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;

  z-index: 1;
}

input.tgl-bar + label::before {
  content: '✓';
  position: absolute;

  right: 23px;
  top: -3px;

  background: #fff;
  border: 1px solid #343434;

  display: block;

  font-size: 18px;
  line-height: 29px;
  text-align: center;

  color: #c0c0c0;

  height: 29px;
  width: 29px;

  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;

  z-index: 2;

  -webkit-transition: 0.5s right;
  -moz-transition: 0.5s right;
  -ms-transition: 0.5s right;
  -o-transition: 0.5s right;
  transition: 0.5s right;
}
input.tgl-bar:checked + label::after {
  content: '✓';
  text-align: left;
  color: #fff;
  background: #454545;
}
input.tgl-bar:checked + label::before {
  content: '✕';
  right: -2px;
}

.reports-dashboard h2.review-id {
  font-weight: bold;
}

.reports-dashboard h2.review-id > span,
.reports-dashboard h2.review-id > i {
  font-weight: normal;
}

.reports-dashboard h2.review-id > span::before {
  content: ' ';
}

.reports-dashboard h2.review-id > i {
  font-size: 16px;
}

.create-review-form > button {
  display: block;
  margin: 15px auto;
}

.edit-review-form-save::before,
.create-review-form-confirm::before {
  content: ' ';

  display: block;

  position: fixed;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 10000;

  background: rgba(128, 128, 128, 0.5);
}

.edit-review-form-save > div,
.create-review-form-confirm > div {
  position: fixed;
  top: 50%;
  left: 50%;

  z-index: 10001;

  width: 95%;
  max-width: 450px;

  padding: 30px 45px;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;

  background: #fff;

  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.edit-review-form-save > div .btn,
.create-review-form-confirm > div .btn {
  margin: 0 15px;
}
.edit-review-form-save > div p,
.create-review-form-confirm > div p {
  font-size: 16px;
  margin-bottom: 15px;
}

[report-state='finalized'].reports-dashboard .report-item--finalize-button,
[report-state='signed'].reports-dashboard .report-item--finalize-button {
  display: none;
}

[report-state='finalized'].reports-dashboard .report-item--save-button,
[report-state='signed'].reports-dashboard .report-item--save-button {
  display: none;
}

[report-state='finalized'].reports-dashboard .report-item--edit-button,
[report-state='signed'].reports-dashboard .report-item--edit-button {
  display: none;
}

.reports-dashboard .report-item--sign-button {
  display: none;
}

[report-state='finalized'].reports-dashboard .report-item--sign-button {
  display: inline-block;
}

[report-state='finalized'].reports-dashboard .report-item--addcomment-button,
[report-state='finalized'].reports-dashboard .report-item--edit-button {
  display: none;
}

[report-max-state='signed'].reports-dashboard .report-item--finalize-button,
[report-max-state='signed'].reports-dashboard .report-item--save-button,
[report-max-state='signed'].reports-dashboard .report-item--sign-button,
[report-max-state='signed'].reports-dashboard .report-item--addcomment-button,
[report-max-state='signed'].reports-dashboard .report-item--edit-button {
  display: none;
}

[report-state-editing='true'].reports-dashboard .report-item--finalize-button,
[report-state-editing='true'].reports-dashboard .report-item--save-button,
[report-state-editing='true'].reports-dashboard .report-item--sign-button,
[report-state-editing='true'].reports-dashboard .report-item--versions-button,
[report-state-editing='true'].reports-dashboard
  .report-item--addcomment-button {
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  -ms-pointer-events: none;
  -o-pointer-events: none;
  pointer-events: none;

  -webkit-opacity: 0.23;
  -moz-opacity: 0.23;
  -ms-opacity: 0.23;
  -o-opacity: 0.23;
  opacity: 0.23;
}

[report-state].reports-dashboard .review-id--state-create,
.reports-dashboard .review-id--state-finalized,
.reports-dashboard .review-id--state-ready-for-share {
  display: none;
}

[report-state='undefined'] .review-id--state-saved,
[report-state='initial'] .review-id--state-saved {
  display: inline-block;
}

[report-state='finalized'] .review-id--state-finalized {
  display: inline-block;
}

[report-state='signed'] .review-id--state-ready-for-share {
  display: inline-block;
}

.reports-dashboard [data-editing='true'].report-item-editor {
  border: 3px solid #ddd;
  box-shadow: 0 0 3px #aaa;
}

.reports-dashboard .report-item--save-button {
  float: right;
}

.reports-dashboard
  [data-editing='true'].report-item-editor
  ~ .report-item--save-button {
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  -ms-pointer-events: none;
  -o-pointer-events: none;
  pointer-events: none;

  -webkit-opacity: 0.3;
  -moz-opacity: 0.3;
  -ms-opacity: 0.3;
  -o-opacity: 0.3;
  opacity: 0.3;

  cursor: default;
}

.report-item-comparing-version-grid {
  width: 100%;
}

.reports-dashboard .report-item-comparing-version-container {
  display: none;
  width: 50%;
  text-align: left;
  height: 100%;
  vertical-align: top;
}

.reports-dashboard .report-item-comparing-version-grid tr {
  vertical-align: top;
}

.reports-dashboard .report-item-comparing-version-container > .btn-controls {
  text-align: right;
}

.reports-dashboard
  .report-item-comparing-version-container
  > .btn-controls
  > .btn {
  margin-left: 10px;
}

.reports-dashboard .report-item-comparing-version-container[report-version-id] {
  display: table-cell;
}

@import '/report-preview/report-template.css';

.reports-dashboard--statistics {
  background: #fff;
  margin: 30px 0;
  padding-top: 10px;
}

.reports-dashboard--statistics h2 {
  font-size: 16px;
}

.reports-dashboard--statistics .row {
  position: relative;

  margin: 0;
}

.reports-dashboard--statistics .row .col-sm-12 {
  position: static;
}

.reports-dashboard--statistics .row .col-sm-12 > label {
  position: absolute;
  top: -8px;
  left: 0;
  width: 100%;
  /*height: 27px;*/
  height: 100%;
}

.reports-dashboard--statistics .row .col-sm-12 > input[type='checkbox'] {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  -ms-opacity: 0;
  -o-opacity: 0;
  opacity: 0;

  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  -ms-pointer-events: none;
  -o-pointer-events: none;
  pointer-events: none;

  position: absolute;
  top: 0;
  left: 0;
}

.reports-dashboard--statistics .row .col-sm-12 > input[type='checkbox'] ~ div {
  display: none;
}

.reports-dashboard--statistics
  .row
  .col-sm-12
  > input[type='checkbox']:checked
  ~ label {
  border: 2px solid #bbbbbb;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;

  -webkit-box-shadow: inset 0 0 30px rgba(128, 128, 128, 0.5);
  box-shadow: inset 0 0 30px rgba(128, 128, 128, 0.5);
}

.reports-dashboard--statistics
  .row
  .col-sm-12
  > input[type='checkbox']:checked
  ~ div {
  display: block;

  text-align: center;

  padding-bottom: 20px;
}

.reports-dashboard--statistics
  .row
  .col-sm-12
  > input[type='checkbox']:checked
  ~ div
  .label {
  display: inline-block;
  font-size: 18px;
  font-weight: bold;

  padding: 15px 25px;

  border: 3px solid #c0c0c0;

  color: #000;

  min-width: 150px;

  margin: 3px 5px;
}

.reports-dashboard--statistics
  .row
  .col-sm-12
  > input[type='checkbox']:checked
  ~ div
  .label.orange {
  border-color: #f98f00;
}

.reports-dashboard--statistics
  .row
  .col-sm-12
  > input[type='checkbox']:checked
  ~ div
  .label.green {
  border-color: #3f9d6e;
}

.reports-dashboard--statistics .col-sm-6 {
  width: 50%;
  float: left;
}

.reports-dashboard--statistics .row .text {
  text-align: right;
}

.reports-dashboard--statistics .row .progress {
  background: transparent;

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  line-height: 1px;

  height: 18px;

  -webkit-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  transform: translateX(-10px);

  -webkit-box-shadow: none;
  box-shadow: none;
}

.reports-dashboard--statistics .row .progress.progress-bg {
  background: #b6b6b6;
}

.reports-dashboard--statistics .row .progress > .value {
  background: #58ccf7;
  line-height: 1px;
  height: 18px;
}

.reports-dashboard--statistics .row .progress.orange > .value {
  background: #f98f00;
}

.reports-dashboard--statistics .row .progress.red > .value {
  background: crimson;
}

@media (max-width: 768px) {
  .reports-dashboard h2.review-id {
    font-size: 14px;
  }

  .reports-dashboard h2.review-id > i {
    font-size: 12px;
  }

  .reports-dashboard > .col-sm-12 {
    padding: 0;
  }

  #main-wrapper > div > .row.well,
  #main-wrapper > div > .row.well > .col-sm-12 {
    padding: 0;
  }

  .reports-dashboard .alert {
    font-size: 14px;
  }

  .reports-dashboard .btn {
    font-size: 11px !important;
    padding: 4px 8px !important;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
  }

  .report-item--content.active-inline-chats--comments-container-context[report-state] {
    padding: 10px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;

    max-width: 95vw;

    overflow-x: auto;
  }
}

@media (min-width: 769px) {
  .reports-dashboard--statistics .row .text {
    max-width: 300px;
  }
}

.reports-dashboard--statistics {
  background: #fff;
  margin: 30px 0;
  padding-top: 10px;
}

.reports-dashboard--statistics h2 {
  font-size: 16px;
}

.reports-dashboard--statistics .row {
  position: relative;

  margin: 0;
}

.reports-dashboard--statistics .row .col-sm-12 {
  position: static;
}

.reports-dashboard--statistics .row .col-sm-12 > label {
  position: absolute;
  top: -8px;
  left: 0;
  width: 100%;
  /*height: 27px;*/
  height: 100%;
}

.reports-dashboard--statistics .row .col-sm-12 > input[type='checkbox'] {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  -ms-opacity: 0;
  -o-opacity: 0;
  opacity: 0;

  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  -ms-pointer-events: none;
  -o-pointer-events: none;
  pointer-events: none;

  position: absolute;
  top: 0;
  left: 0;
}

.reports-dashboard--statistics .row .col-sm-12 > input[type='checkbox'] ~ div {
  display: none;
}

.reports-dashboard--statistics
  .row
  .col-sm-12
  > input[type='checkbox']:checked
  ~ label {
  border: 2px solid #bbbbbb;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;

  -webkit-box-shadow: inset 0 0 30px rgba(128, 128, 128, 0.5);
  box-shadow: inset 0 0 30px rgba(128, 128, 128, 0.5);
}

.reports-dashboard--statistics
  .row
  .col-sm-12
  > input[type='checkbox']:checked
  ~ div {
  display: block;

  text-align: center;

  padding-bottom: 20px;
}

.reports-dashboard--statistics
  .row
  .col-sm-12
  > input[type='checkbox']:checked
  ~ div
  .label {
  display: inline-block;
  font-size: 18px;
  font-weight: bold;

  padding: 15px 25px;

  border: 3px solid #c0c0c0;

  color: #000;

  min-width: 150px;

  margin: 3px 5px;
}

.reports-dashboard--statistics
  .row
  .col-sm-12
  > input[type='checkbox']:checked
  ~ div
  .label.orange {
  border-color: #f98f00;
}

.reports-dashboard--statistics
  .row
  .col-sm-12
  > input[type='checkbox']:checked
  ~ div
  .label.red {
  border-color: crimson;
}

.reports-dashboard--statistics
  .row
  .col-sm-12
  > input[type='checkbox']:checked
  ~ div
  .label.blue {
  border-color: #58ccf7;
}

.reports-dashboard--statistics
  .row
  .col-sm-12
  > input[type='checkbox']:checked
  ~ div
  .label.green {
  border-color: #3f9d6e;
}

.reports-dashboard--statistics .col-sm-6 {
  width: 50%;
  float: left;
}

.reports-dashboard--statistics .row .text {
  text-align: right;
}

.reports-dashboard--statistics .row .progress {
  background: transparent;

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  line-height: 1px;

  height: 18px;

  -webkit-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  transform: translateX(-10px);

  -webkit-box-shadow: none;
  box-shadow: none;
}

.reports-dashboard--statistics .row .progress.progress-bg {
  background: #b6b6b6;
}

.reports-dashboard--statistics .row .progress > .value {
  background: #58ccf7;
  line-height: 1px;
  height: 18px;
}

.reports-dashboard--statistics .row .progress.orange > .value {
  background: #f98f00;
}

@media (max-width: 768px) {
  .reports-dashboard h2.review-id {
    font-size: 14px;
  }

  .reports-dashboard h2.review-id > i {
    font-size: 12px;
  }

  .reports-dashboard > .col-sm-12 {
    padding: 0;
  }

  #main-wrapper > div > .row.well,
  #main-wrapper > div > .row.well > .col-sm-12 {
    padding: 0;
  }

  .reports-dashboard .alert {
    font-size: 14px;
  }

  .reports-dashboard .btn {
    font-size: 11px !important;
    padding: 4px 8px !important;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
  }

  .report-item--content.active-inline-chats--comments-container-context[report-state] {
    padding: 10px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;

    max-width: 95vw;

    overflow-x: auto;
  }
}

@media (min-width: 769px) {
  .reports-dashboard--statistics .row .text {
    max-width: 300px;
  }
}

.report-item--share-container-wrapper {
  position: fixed;
  background: rgba(0, 0, 0, 0.67);
  z-index: 100000000;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.report-item--share-container {
  position: absolute;

  top: 5%;
  right: 5%;
  width: 57.5%;
  height: 90%;

  background: #fff;

  overflow: auto;
}

.report-item--share-container.wide-preview {
  width: 90%;
}

.report-item--share-container-wrapper > .shared-message {
  background: #fff;

  position: absolute;

  overflow: auto;

  top: 5%;
  left: 2.5%;
  width: 32.5%;
  max-height: 60%;
  text-align: center;
  padding: 10px 0;
}

.report-item--share-container-wrapper > .shared-message::before {
  content: 'Report Share - Email Message Preview';
  font-size: 18px;
  line-height: 1.5;
  display: block;
  padding: 15px;

  font-weight: bold;

  border-bottom: 1px solid #c0c0c0;

  margin-bottom: 15px;
}

.report-item--share-container-wrapper > .shared-message > p {
  padding: 15px 25px;
  white-space: pre-line;
  word-wrap: break-word;
  text-align: left;
}

.report-item--share-container-wrapper > .buttons {
  background: #fff;

  position: absolute;

  bottom: 5%;
  left: 2.5%;
  width: 32.5%;
  text-align: center;
  padding: 10px 0;
}

.report-item--share-container-wrapper > .buttons::before {
  content: 'Report Share Action';
  font-size: 18px;
  line-height: 1.5;
  display: block;
  padding: 15px;
}

.report-item--share-container-wrapper > .buttons > .btn {
  margin: 10px;
  padding: 10px 15px;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.reports-dashboard--statistics--mobile,
.reports-dashboard--statistics--desktop {
  display: none;
}

@media (max-width: 1024px) {
  .reports-dashboard--statistics--mobile {
    display: block;
  }
}

@media (min-width: 1025px) {
  .reports-dashboard--statistics--desktop {
    display: block;
  }
}

.timeline-group {
  white-space: nowrap;

  overflow-y: visible;

  text-align: center;

  display: block;
  padding: 120px 0;

  margin: 0 69px;

  cursor: default;
}

.timeline-group section {
  display: inline-block;

  white-space: normal;

  position: relative;

  height: 5px;
  width: 60px;

  background: #c0c0c0;

  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.timeline-group section:last-child {
  width: 3px;
}

.timeline-group section::before {
  content: ' ';

  position: absolute;

  left: 0;
  top: 50%;

  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  height: 12px;
  width: 12px;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;

  background: #c0c0c0;

  -webkit-transition: 0.5s background;
  -moz-transition: 0.5s background;
  -ms-transition: 0.5s background;
  transition: 0.5s background;
}

.timeline-group section[data-progress]::after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;

  -webkit-transition: 0.5s width;
  -moz-transition: 0.5s width;
  -ms-transition: 0.5s width;
  transition: 0.5s width;
}

.timeline-group section[data-progress]:not([data-progress=''])::before,
.timeline-group section[data-progress]:not([data-progress=''])::after,
.timeline-group section[data-progress]:not([data-progress='']) article::before {
  background: rgb(62, 200, 255);
}
.timeline-group section[data-progress]:not([data-progress='']) article {
  border-color: rgb(62, 200, 255);
}

.timeline-group
  section[data-color='yellow'][data-progress]:not([data-progress=''])::before,
.timeline-group
  section[data-color='yellow'][data-progress]:not([data-progress=''])::after,
.timeline-group
  section[data-color='yellow'][data-progress]:not([data-progress=''])
  article::before {
  background: goldenrod;
}
.timeline-group
  section[data-color='yellow'][data-progress]:not([data-progress=''])
  article {
  border-color: goldenrod;
}

.timeline-group
  section[data-color='red'][data-progress]:not([data-progress=''])::before,
.timeline-group
  section[data-color='red'][data-progress]:not([data-progress=''])::after,
.timeline-group
  section[data-color='red'][data-progress]:not([data-progress=''])
  article::before {
  background: crimson;
}
.timeline-group
  section[data-color='red'][data-progress]:not([data-progress=''])
  article {
  border-color: crimson;
}

.timeline-group section[data-progress^='0.']::after {
  width: 5%;
}
.timeline-group section[data-progress^='0.1']::after {
  width: 10%;
}
.timeline-group section[data-progress^='0.15']::after {
  width: 15%;
}
.timeline-group section[data-progress^='0.2']::after {
  width: 20%;
}
.timeline-group section[data-progress^='0.25']::after {
  width: 25%;
}
.timeline-group section[data-progress^='0.3']::after {
  width: 30%;
}
.timeline-group section[data-progress^='0.35']::after {
  width: 35%;
}
.timeline-group section[data-progress^='0.4']::after {
  width: 40%;
}
.timeline-group section[data-progress^='0.45']::after {
  width: 45%;
}
.timeline-group section[data-progress^='0.5']::after {
  width: 50%;
}
.timeline-group section[data-progress^='0.55']::after {
  width: 55%;
}
.timeline-group section[data-progress^='0.6']::after {
  width: 60%;
}
.timeline-group section[data-progress^='0.65']::after {
  width: 65%;
}
.timeline-group section[data-progress^='0.7']::after {
  width: 70%;
}
.timeline-group section[data-progress^='0.75']::after {
  width: 75%;
}
.timeline-group section[data-progress^='0.8']::after {
  width: 80%;
}
.timeline-group section[data-progress^='0.85']::after {
  width: 85%;
}
.timeline-group section[data-progress^='0.9']::after {
  width: 90%;
}
.timeline-group section[data-progress^='0.95']::after {
  width: 95%;
}
.timeline-group section[data-progress^='1']::after {
  width: 100%;
}

.timeline-group section article {
  position: absolute;
  left: 0;
  bottom: 50%;

  font-family: arial;

  font-size: 12px;

  border: 2px solid #c0c0c0;

  padding: 5px 10px;

  min-width: 110px;

  background: #fff;

  -webkit-transform: translateY(-20px) translateX(-50%);
  -moz-transform: translateY(-20px) translateX(-50%);
  -ms-transform: translateY(-20px) translateX(-50%);
  transform: translateY(-20px) translateX(-50%);
}

.timeline-group section:hover {
  z-index: 1000;
}

.timeline-group section article h1,
.timeline-group section article p {
  font-size: 12px;

  line-height: 1.25;

  margin: 0;
}

.timeline-group section article::before {
  content: ' ';

  width: 3px;

  height: 20px;

  background: #c0c0c0;

  position: absolute;
  left: 50%;
  top: 100%;

  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.timeline-group section:nth-child(2n + 1) article {
  bottom: initial;
  top: 50%;

  -webkit-transform: translateY(20px) translateX(-50%);
  -moz-transform: translateY(20px) translateX(-50%);
  -ms-transform: translateY(20px) translateX(-50%);
  transform: translateY(20px) translateX(-50%);
}

.timeline-group section:nth-child(2n + 1) article::before {
  position: absolute;
  left: 50%;
  top: 0;

  -webkit-transform: translate(-50%, -100%);
  -moz-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}

.font-size-1-1-em {
  font-size: 1.1em;
}

.external-shares-table {
  width: 100%;
}

.external-shares-table th,
.external-shares-table td {
  width: 25%;
  border: 1px solid gray;
  padding: 0.5rem;
}
