.report-comment-container {
  position: fixed;

  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  background: #82858e;

  overflow: auto;
}

.report-comment-container [active-inline-chats] {
  background-color: transparent !important;
}

.report-comment-container > div {
  position: static;

  background: #fff;

  margin: 15px auto;

  padding: 15px 40px;

  text-align: left;

  width: auto;

  max-width: 90%;

  font-size: 18px;

  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  -o-border-radius: 9px;
  border-radius: 9px;
}

.download-report-action.report-comment-container > div {
  max-width: 540px;
}

.report-comment-container > div label {
  margin-top: 15px;
  font-size: 18px;
}

.report-comment-container > div > input[type='text'],
.report-comment-container > div > input[type='date'],
.report-comment-container > div textarea {
  padding: 10px 20px;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  display: block;
  width: auto;

  margin: 10px auto;

  width: 100%;

  font-size: 14px;
}

.report-comment-container > div textarea[disabled] {
  background: #dedede;
}

.report-comment-container > div textarea {
  height: 160px;
}

.report-comment-container > div .buttons {
  margin: 10px auto 0 0;
  text-align: center;
}

.report-comment-container > div .buttons button {
  border: 0;

  cursor: pointer;

  padding: 15px 30px;

  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  border-radius: 7px;

  font-size: 18px;

  margin: 5px 15px;
}

.report-comment-container > div .buttons button.submit {
  background: #5b606b;
  border-color: #5b606b;
  color: #fff;
}

.report-comment-container > div .buttons button.approve,
.report-comment-container > div .buttons button.reject {
  float: left;
}

.report-comment-container > div h3,
.report-comment--user-message-list:not(:empty)::before {
  font-size: 1.2em;
  font-weight: bold;

  display: block;

  margin: 15px 0;

  content: attr(title);
}

.report-comment--user-message-list:empty {
  display: none;
}

.report-comment--user-message {
  margin-top: 15px;
  border-top: 1px solid #c0c0c0;
  padding-top: 5px;
}

.selected-file {
  display: inline-block;
  margin-left: 18px;
  margin-top: 3px;
  background: #cfcfcf;
  border-color: #7b7b7b;
  color: #636363;
}

.selected-file:hover,
.selected-file:active {
  background: #cfcfcf !important;
  border-color: #7b7b7b !important;
  color: #636363 !important;
  box-shadow: none;
  cursor: default;
}

.selected-file--close {
  cursor: pointer;
  display: inline-block;
  width: 16px;
  line-height: 16px;
  background: #fff;
  color: #000;
  border-radius: 3px;
  font-size: 12px;
  text-align: center;
  margin-left: 10px;
}

.uploading-comment-files {
  padding: 15px 10px;
}

.report-comment-container > div > .row > .uploading-comment-files ~ .buttons {
  text-align: left;
}

.download-report-action.report-comment-container > div::before {
  display: none;
}
