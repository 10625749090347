#childFileOutcome table {
  border-collapse: collapse;
  width: 100%;
  margin: 2rem auto;
  margin-bottom: 20px;
}
#childFileOutcome table th,
#childFileOutcome table td {
  border: 1px solid #ddd;
  padding: 8px;
}
