.ui.borderless.menu {
  background-color: #f8f8f8;
  box-shadow: none;
  flex-wrap: wrap;
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.ui.borderless.menu .header.item {
  font-size: 18px;
  font-weight: 400;
}

.ui.mobile.only.grid .ui.menu .ui.vertical.menu {
  display: none;
}

.ui.mobile.only.grid .ui.vertical.menu .dropdown.icon {
  float: unset;
}

.ui.mobile.only.grid .ui.vertical.menu .dropdown.icon:before {
  content: '\f0d7';
}

.ui.mobile.only.grid .ui.vertical.menu .ui.dropdown.item .menu {
  position: static;
  width: 100%;
  background-color: unset;
  border: none;
  box-shadow: none;
}

.ui.mobile.only.grid .ui.vertical.menu .ui.dropdown.item .menu {
  margin-top: 6px;
}

.header-search-input {
  margin: 0 auto;
}

.header-search-input::before {
  display: none;
}

.ui.dropdown .caret {
  vertical-align: inherit !important;
  border: none !important;
}

.ui.segment .profile-page {
  margin: 0;
}

.circle-avatar {
  display: inline-block;
  min-width: 60px;
  min-height: 60px;
  line-height: 60px;
  font-size: 1.4em;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background: #4773aa;
  border-radius: 50%;
}

.sidebar .circle-avatar {
  display: inline-block;
  width: 90px;
  height: 90px;
  line-height: 90px;
  font-size: 2.4em;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background: #4773aa;
  border-radius: 50%;
  font-weight: normal;
  padding: 0;
}

/* Widget */
.widget {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 6px;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.widget.box .widget-header {
  background: #fff;
  padding: 0px 8px 0px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.widget .widget-header h4 {
  color: #000000;
  font-size: 17px;
  font-weight: 600;
  margin: 0;
  padding: 16px 15px;
  border-bottom: 1px solid #e0e6ed;
}

.widget .widget-header {
  border-bottom: 0px solid #f1f2f3;
}

.widget .widget-header:before,
.widget .widget-header:after {
  display: table;
  content: '';
  line-height: 0;
}

.widget .widget-header:after {
  clear: both;
}

.widget-content-area {
  padding: 20px;
  position: relative;
  background-color: #fff;
  border-radius: 0px;
}

#eas-form-hide-accordion .accordion  > .title {
  display: none;
}

#eas-form-hide-accordion .accordion  > .content {
  padding: 0px !important;
}

#eas-form-hide-accordion #eas-form {
  margin: 0px -15px;
}

#eas-form .rjsf > .form-group {
  margin-bottom: 0px;
}

#eas-form #root {
  padding: 0px;
}

#eas-form #root > .form-group > fieldset {
  border: none;
  padding: 0px;
  position: relative;
}

#eas-form #root > .form-group > fieldset > legend {
  display: none;
}

#eas-form #root > .form-group > fieldset > div {
  padding: 0px 15px;
}

/* nested header */
#eas-form #root > .form-group > fieldset > .form-group > fieldset > legend {
  font-size: 16px;
  border: none;
  font-weight: 600;
  margin-bottom: 0px;
}

#eas-form legend[id$='_items_radio_categories__title'] {
  border: none;
  font-size: 14px;
  line-height: 1.4285em;
  margin-bottom: 5px;
}

#eas-form .array-item {
  border-bottom: 1px solid #e0e6ed;
  margin-bottom: 20px;
}

#eas-form .array-item .form-group {
  margin-bottom: 15px;
  padding-left: 15px;
}

#eas-form .array-item .form-group > label {
  margin-bottom: 0px;
  margin-top: 0px;
}

#eas-form .array-item .array-item-label {
  padding: 0px 15px;
  font-size: 16px;
  margin-bottom: 0px;
}

.eas-file-link .field-string {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.eas-file-link a:focus {
  border: 2px solid #245e9d;
}

.eas-file-link i.close {
  line-height: 21px;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 100;
  color: #757575;
  text-decoration: none;
}

.eas-custom-attach {
  visibility: hidden !important;
}

.script {
  background-color: rgb(255, 245, 224);
  margin: 0px -30px;
  padding: 15px 30px;
}

.reference-notes {
  font-size: 13px;
}
.eas-progress-bar {
  margin-top: 15px;
}
.eas-progress-bar .bar {
  background-color: rgb(39, 93, 157) !important;
}

.eas-progress-bar {
  margin: 0 !important;
}

.eas-file-link {
  display: flex;
  padding: 0px 10px 0px 0px;
  margin-top: 10px;
  width: 100%;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #d9d9d9;
  min-height: 70px;
  max-height: 150px;
  margin-top: 20px;
  line-height: 70px;
}

.eas-delete-button {
  height: 35px;
  margin-top: 15px !important;
  position: absolute;
  right: 0;
}

/* Add some hover effect for better user interaction */

.eas-file-link .form-group {
  margin-bottom: 0px;
}

.survey-questions fieldset {
  padding: 0px;
}

.survey-questions .accordion .content {
  padding: 0px 15px !important;
}

.control-label[for$='_responsive_script'] {
  display: none;
}

.form-group.field.field-array:has(.responsive-script) {
  background-color: rgb(255, 245, 224);
  margin: -15px -15px 15px;
  padding: 0px 20px 15px !important;
}

.responsive-script {
  background-color: #fff;
  padding: 10px;
}

#eas-form input[type='radio'] {
  accent-color: #232323;
  width: 14px;
  height: 14px;
}

#eas-form input[type='checkbox'] {
  accent-color: #fff;
  width: 14px;
  height: 14px;
}

#eas-form input[type='checkbox']:checked {
  outline: 1px solid #232323;
  outline-offset: 0px;
}

.page-title .sub.header {
  font-size: 14px !important;
  color: #000000 !important;
  font-weight: 400 !important;
  font-family: 'PublicSans-Regular' !important;
}

.page-title .sub.header > span {
  font-weight: 600 !important;
  display: block;
  padding-bottom: 5px;
  padding-top: 5px;
}

.trix-editor-disabled button:not([disabled]) {
  color: rgba(0, 0, 0, 0.125) !important;
  cursor: default !important;
}

.trix-editor-disabled button:not([disabled])::before {
  opacity: 0.125 !important;
}

.trix-editor-disabled .trix-button-row {
  display: none;
}