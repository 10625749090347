.formHead-2-2-2 {
  width: 100% !important;
  margin-bottom: 20px !important;
  border-radius: 0px !important;
  background-color: #eee !important;
  border-color: #ccc !important;
  display: none !important;
}

#eas-form-builder .alert-warning {
  display: none !important;
}

#eas-form-builder div[data-test='form-head'],
#eas-form-builder.edit
  .section-entries
  > .section-head
  div[data-test='section-object-name'] {
  display: none;
}

#eas-form-builder > div > .form-footer > div,
#eas-form-builder > div > .form-body > div > div > div:last-child {
  display: none !important;
}

#eas-form-builder .ui.fluid.dropdown {
  display: inline-block;
}

#eas-form-builder .ui.search.dropdown > .text {
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
}

#eas-form-builder trix-editor {
  text-align: left;
}

#eas-form-builder .focusable-wsywig-form-input label {
  text-align: left;
}

#eas-form-builder .wsywig-input-unfocused {
  height: 150px;
  text-align: left;
  overflow-y: scroll;
}

#eas-form-builder trix-toolbar .trix-button-group--file-tools {
  display: none;
}

.section-entry {
  margin-bottom: 0px;
  float: left;
}

.section-head {
  display: block !important;
  overflow: hidden;
}

.card-container,
.section-container {
  background: #eee !important;
  border: 1px solid #ccc !important;
  border-radius: 0 !important;
  box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%) !important;
  padding: 0px !important;
  width: 100% !important;
  margin: 0px !important;
  margin-bottom: 5px !important;
  margin-top: 0px !important;
}

/*Section Header */
.card-container > .d-flex,
.section-container > .d-flex {
  border-bottom: none !important;
  padding: 5px 15px 0px !important;
  position: relative;
}

.card-container > .d-flex > .toggle-collapse,
.section-container > .d-flex > .toggle-collapse {
  margin-left: 0px !important;
  font-size: 16px !important;
  color: #138ac2 !important;
  position: absolute;
  top: 10px;
}

.card-container > .d-flex > h4,
.section-container > .d-flex > h4 {
  font-size: 22px !important;
  padding: 0px !important;
  margin: 0px !important;
  color: #000 !important;
  line-height: 22px !important;
  position: relative;
}

.card-container > .d-flex > h4 > .arrows svg,
.section-container > .d-flex > h4 > .arrows svg {
  width: 14px !important;
  height: 14px !important;
  background-color: #1d3665 !important;
  border-color: #1d3665 !important;
  color: #fff !important;
  margin: 0px !important;
  margin-left: 5px !important;
}

.card-container > .d-flex > h4 .label,
.section-container > .d-flex > h4 .label {
  color: #000;
  line-height: 16px !important;
  font-size: 16px !important;
  margin-top: 2px !important;
  cursor: pointer !important;
  position: absolute;
  top: -6px;
  left: -15px;
  right: 60px;
  bottom: -5px;
  padding: 9px 10px 9px 30px;
  overflow: hidden;
  text-align: left;
}

.card-container .toggle-collapse .fa-caret-down,
.section-container .toggle-collapse .fa-caret-down {
  margin-right: 0px !important;
}

.card-container .collapsing,
.card-container .collapse,
.section-container .collapsing,
.section-container .collapse {
  border-top: 1px solid #ccc !important;
  background-color: #fafafa !important;
  padding: 0px 15px 15px !important;
}

.section-container .section-head {
  margin: 0px -15px !important;
  border: none !important;
}

.card-container div[class^='cardEntries'] {
  margin: 0px -5px !important;
  border-bottom: none !important;
}

.card-container
  div[class^='cardEntries']
  .card-entry-row:first-child
  .card-entry {
  width: 100%;
}
/*Hide Object Name */
div[data-rbd-draggable-id$='tq_answer']
  .card-container
  div[class^='cardEntries']
  .card-entry-row:nth-child(2)
  .card-entry:first-child {
  display: none;
}

div[data-rbd-draggable-id$='tq_answer']
  .card-container
  div[class^='cardEntries']
  .card-entry-row:first-child
  .card-entry:first-child {
  display: none;
}

div[data-rbd-draggable-id$='textbox']
  .card-container
  div[class^='cardEntries']
  .card-entry-row:first-child
  .card-entry:first-child {
  display: none;
}

div[data-rbd-draggable-id$='percentage_calculation']
  .card-container
  div[class^='cardEntries']
  .card-entry-row:first-child
  .card-entry:first-child {
  display: none;
}

div[data-rbd-draggable-id$='percentage_calculation']
  .card-container
  div[class^='cardEntries']
  .card-entry-row:nth-child(2)
  .card-entry:first-child {
  display: none;
}

div[data-rbd-draggable-id$='no_checkbox_list']
  .card-container
  div[class^='cardEntries']
  .card-entry-row:first-child
  .card-entry:first-child {
  display: none;
}

div[data-rbd-draggable-id$='yes_checkbox_list']
  .card-container
  div[class^='cardEntries']
  .card-entry-row:first-child
  .card-entry:first-child {
  display: none;
}

div[data-rbd-draggable-id$='na_checkbox_list']
  .card-container
  div[class^='cardEntries']
  .card-entry-row:first-child
  .card-entry:first-child {
  display: none;
}

div[data-rbd-draggable-id$='checklist']
  .card-container
  div[class^='cardEntries']
  .card-entry-row:first-child
  .card-entry:first-child {
  display: none;
}

div[data-rbd-draggable-id$='items_radio_categories']
  .card-container
  div[class^='cardEntries']
  .card-entry-row:first-child
  .card-entry:first-child {
  display: none;
}

div[data-rbd-draggable-id$='na_checkbox']
  .card-container
  div[class^='cardEntries']
  .card-entry-row:first-child
  .card-entry:first-child {
  display: none;
}

div[data-rbd-draggable-id$='yes_date']
  .card-container
  div[class^='cardEntries']
  .card-entry-row:first-child
  .card-entry:first-child {
  display: none;
}

div[data-rbd-draggable-id$='notes']
  .card-container
  div[class^='cardEntries']
  .card-entry-row:first-child
  .card-entry:first-child {
  display: none;
}

div[data-rbd-draggable-id$='evidence']
  .card-container
  div[class^='cardEntries']
  .card-entry-row:first-child
  .card-entry:first-child {
  display: none;
}

.card-container
  div[class^='cardEntries']
  .card-entry-row:nth-child(2)
  .card-entry {
  width: 100%;
}

.card-container .card-entry {
  padding: 0px 10px !important;
}

.card-container .card-category-options {
  padding: 5px 10px 15px;
}

.card-container .card-category-options h5 {
  font-family: inherit !important;
  color: #333 !important;
  font-weight: 600 !important;
  margin-bottom: 5px;
  margin-top: 0px;
  text-align: left;
  font-size: 15px;
}

.card-container .card-category-options .card-enum {
  background-color: #dadada !important;
  border: 1px solid #c2c2c2 !important;
}

.card-container .card-category-options .card-enum .delete-button {
  background-color: #b0383e !important;
  border: 1px solid #b0383e !important;
  color: #fff !important;
  border-radius: 4px !important;
  padding: 0px 11px !important;
}

.card-container .card-category-options .card-enum .fa-plus {
  background: #7d9b4f !important;
  display: inline-block !important;
  padding: 8px 24px !important;
  color: #fff !important;
  border-radius: 4px !important;
  width: 80%;
}

.card-container .card-category-options .card-enum h3,
.card-container .card-category-options .card-enum label,
.card-container .card-entry > h5,
.section-container .section-head > .section-entry > h5 {
  font-family: inherit !important;
  color: #333 !important;
  font-weight: 600 !important;
  margin-bottom: 5px;
  margin-top: 10px;
}

.section-head .section-entry {
  margin-bottom: 10px;
}

.card-container .section-entry,
.section-container .section-entry {
  padding: 0px 15px !important;
}

.card-container .section-footer,
.section-container .section-footer {
  margin-top: 0px !important;
}

.fa-plus-square {
  margin-bottom: 3px !important;
  color: #7d9b4f !important;
}

.card-container div[class^='cardInteractions'],
.section-container .section-interactions {
  border-top: 1px solid #ccc !important;
  background: #fafafa !important;
  margin: 0px -15px -15px !important;
  padding: 10px 15px 5px !important;
  overflow: hidden !important;
}

.card-container div[class^='cardInteractions'] > *,
.section-container .section-interactions > * {
  float: left !important;
  display: block !important;
}

.card-container div[class^='cardInteractions'] .action-checkbox,
.section-container .section-interactions .action-checkbox {
  margin-top: 3px;
}

.card-container div[class^='cardInteractions'] .action-checkbox label,
.section-container .section-interactions .action-checkbox label {
  font-family: inherit !important;
  color: #333 !important;
  font-weight: 600 !important;
}

.card-container div[class^='cardInteractions'] .fa,
.section-container .section-interactions .fa {
  width: 18px !important;
  height: 18px !important;
}

.card-container div[class^='cardInteractions'] .fa-pencil-alt,
.section-container .section-interactions .fa-pencil-alt {
  border-color: #1d3665 !important;
  background-color: #1d3665 !important;
  color: #fff !important;
  margin-right: 5px !important;
}

.card-container div[class^='cardInteractions'] .fa-trash,
.section-container .section-interactions .fa-trash {
  border-color: #b0383e !important;
  background-color: #b0383e !important;
  color: #fff !important;
  margin-right: 25px !important;
}

#root .section-entries > .section-head div[data-test='section-object-name'],
#root .section-entries > .section-head div[data-test='section-description'],
#root .section-entries > .section-head div[data-test='section-display-name'] {
  display: none;
}

#root .section-entries > .section-interactions {
  display: none;
}

#root .section-entries > .section-body > div {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 15px;
}

#root .section-entries > .section-body > div > div {
  padding-left: 15px;
  padding-right: 15px;
  clear: left;
  clear: both;
}

#root .section-entries > .section-body > div > div > div:last-child {
  display: none !important;
}

#root .section-entries > .section-body > div > div:last-child > div:last-child {
  display: inline !important;
}

div[data-rbd-draggable-id$='reference_notes'],
div[data-rbd-draggable-id$='tags'],
div[data-rbd-draggable-id$='script'],
div[data-rbd-draggable-id$='guidance'],
div[data-rbd-draggable-id$='citations'] {
  position: relative;
  min-height: 1px;
  width: 100%;
  float: right;
  clear: none !important;
}

.tags-input,
.order-input {
  padding: 9px 12px;
  height: 40px;
}

div[data-rbd-draggable-id$='order'] .card-container,
div[data-rbd-draggable-id$='tags'] .card-container,
div[data-rbd-draggable-id$='performance_area'] .card-container,
div[data-rbd-draggable-id$='performance_measure'] .card-container,
div[data-rbd-draggable-id$='content_area'] .card-container,
div[data-rbd-draggable-id$='evidence_source'] .card-container,
div[data-rbd-draggable-id$='citations'] .card-container,
div[data-rbd-draggable-id$='reference_notes'] .card-container,
div[data-rbd-draggable-id$='script'] .card-container,
div[data-rbd-draggable-id$='guidance'] .card-container,
div[data-rbd-draggable-id$='finding_category'] .card-container,
div[data-rbd-draggable-id$='review_type'] .card-container,
div[data-rbd-draggable-id$='fiscal_year'] .card-container,
div[data-rbd-draggable-id$='program_type'] .card-container,
div[data-rbd-draggable-id$='accordion_title'] .card-container,
div[data-rbd-draggable-id$='program_option'] .card-container {
  border: none !important;
  box-shadow: none !important;
  min-width: 100% !important;
}

div[data-rbd-draggable-id$='order'] .card-container .d-flex,
div[data-rbd-draggable-id$='tags'] .card-container .d-flex,
div[data-rbd-draggable-id$='performance_area'] .card-container .d-flex,
div[data-rbd-draggable-id$='performance_measure'] .card-container .d-flex,
div[data-rbd-draggable-id$='content_area'] .card-container .d-flex,
div[data-rbd-draggable-id$='evidence_source'] .card-container .d-flex,
div[data-rbd-draggable-id$='citations'] .card-container .d-flex,
div[data-rbd-draggable-id$='reference_notes'] .card-container .d-flex,
div[data-rbd-draggable-id$='script'] .card-container .d-flex,
div[data-rbd-draggable-id$='guidance'] .card-container .d-flex,
div[data-rbd-draggable-id$='finding_category'] .card-container .d-flex,
div[data-rbd-draggable-id$='review_type'] .card-container .d-flex,
div[data-rbd-draggable-id$='fiscal_year'] .card-container .d-flex,
div[data-rbd-draggable-id$='program_type'] .card-container .d-flex,
div[data-rbd-draggable-id$='accordion_title'] .card-container .d-flex,
div[data-rbd-draggable-id$='program_option'] .card-container .d-flex {
  display: none !important;
}

div[data-rbd-draggable-id$='order'] .card-container .collapse,
div[data-rbd-draggable-id$='tags'] .card-container .collapse,
div[data-rbd-draggable-id$='performance_area'] .card-container .collapse,
div[data-rbd-draggable-id$='performance_measure'] .card-container .collapse,
div[data-rbd-draggable-id$='content_area'] .card-container .collapse,
div[data-rbd-draggable-id$='evidence_source'] .card-container .collapse,
div[data-rbd-draggable-id$='citations'] .card-container .collapse,
div[data-rbd-draggable-id$='reference_notes'] .card-container .collapse,
div[data-rbd-draggable-id$='script'] .card-container .collapse,
div[data-rbd-draggable-id$='guidance'] .card-container .collapse,
div[data-rbd-draggable-id$='finding_category'] .card-container .collapse,
div[data-rbd-draggable-id$='review_type'] .card-container .collapse,
div[data-rbd-draggable-id$='fiscal_year'] .card-container .collapse,
div[data-rbd-draggable-id$='program_type'] .card-container .collapse,
div[data-rbd-draggable-id$='accordion_title'] .card-container .collapse,
div[data-rbd-draggable-id$='program_option'] .card-container .collapse {
  display: block !important;
  border: none !important;
  background-color: #fafafa !important;
  padding: 0px !important;
}

div[data-rbd-draggable-id$='order']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-entry-row,
div[data-rbd-draggable-id$='tags']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-entry-row,
div[data-rbd-draggable-id$='performance_area']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-entry-row,
div[data-rbd-draggable-id$='performance_measure']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-entry-row,
div[data-rbd-draggable-id$='content_area']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-entry-row,
div[data-rbd-draggable-id$='evidence_source']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-entry-row,
div[data-rbd-draggable-id$='citations']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-entry-row,
div[data-rbd-draggable-id$='reference_notes']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-entry-row,
div[data-rbd-draggable-id$='script']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-entry-row,
div[data-rbd-draggable-id$='guidance']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-entry-row,
div[data-rbd-draggable-id$='finding_category']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-entry-row,
div[data-rbd-draggable-id$='review_type']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-entry-row,
div[data-rbd-draggable-id$='fiscal_year']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-entry-row,
div[data-rbd-draggable-id$='program_type']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-entry-row,
div[data-rbd-draggable-id$='accordion_title']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-entry-row,
div[data-rbd-draggable-id$='program_option']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-entry-row {
  display: none !important;
}

div[data-rbd-draggable-id$='order']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-category-options,
div[data-rbd-draggable-id$='tags']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-category-options,
div[data-rbd-draggable-id$='performance_area']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-category-options,
div[data-rbd-draggable-id$='performance_measure']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-category-options,
div[data-rbd-draggable-id$='content_area']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-category-options,
div[data-rbd-draggable-id$='evidence_source']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-category-options,
div[data-rbd-draggable-id$='citations']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-category-options,
div[data-rbd-draggable-id$='reference_notes']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-category-options,
div[data-rbd-draggable-id$='script']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-category-options,
div[data-rbd-draggable-id$='guidance']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-category-options,
div[data-rbd-draggable-id$='finding_category']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-category-options,
div[data-rbd-draggable-id$='review_type']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-category-options,
div[data-rbd-draggable-id$='fiscal_year']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-category-options,
div[data-rbd-draggable-id$='program_type']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-category-options,
div[data-rbd-draggable-id$='accordion_title']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-category-options,
div[data-rbd-draggable-id$='program_option']
  .card-container
  .collapse
  div[class^='cardEntries']
  .card-category-options {
  padding-top: 0px !important;
}

div[data-rbd-draggable-id$='order']
  .card-container
  .collapse
  div[class^='cardInteractions'],
div[data-rbd-draggable-id$='tags']
  .card-container
  .collapse
  div[class^='cardInteractions'],
div[data-rbd-draggable-id$='performance_area']
  .card-container
  .collapse
  div[class^='cardInteractions'],
div[data-rbd-draggable-id$='performance_measure']
  .card-container
  .collapse
  div[class^='cardInteractions'],
div[data-rbd-draggable-id$='content_area']
  .card-container
  .collapse
  div[class^='cardInteractions'],
div[data-rbd-draggable-id$='evidence_source']
  .card-container
  .collapse
  div[class^='cardInteractions'],
div[data-rbd-draggable-id$='citations']
  .card-container
  .collapse
  div[class^='cardInteractions'],
div[data-rbd-draggable-id$='reference_notes']
  .card-container
  .collapse
  div[class^='cardInteractions'],
div[data-rbd-draggable-id$='script']
  .card-container
  .collapse
  div[class^='cardInteractions'],
div[data-rbd-draggable-id$='guidance']
  .card-container
  .collapse
  div[class^='cardInteractions'],
div[data-rbd-draggable-id$='finding_category']
  .card-container
  .collapse
  div[class^='cardInteractions'] {
  display: none !important;
}
div[data-rbd-draggable-id$='review_type']
  .card-container
  .collapse
  div[class^='cardInteractions'] {
  display: none !important;
}
div[data-rbd-draggable-id$='fiscal_year']
  .card-container
  .collapse
  div[class^='cardInteractions'] {
  display: none !important;
}
div[data-rbd-draggable-id$='program_type']
  .card-container
  .collapse
  div[class^='cardInteractions'] {
  display: none !important;
}
div[data-rbd-draggable-id$='accordion_title']
  .card-container
  .collapse
  div[class^='cardInteractions'] {
  display: none !important;
}
div[data-rbd-draggable-id$='program_option']
  .card-container
  .collapse
  div[class^='cardInteractions'] {
  display: none !important;
}

div[data-rbd-draggable-id$='order'] svg[data-icon='square-plus'],
div[data-rbd-draggable-id$='tags'] svg[data-icon='square-plus'],
div[data-rbd-draggable-id$='performance_area'] svg[data-icon='square-plus'],
div[data-rbd-draggable-id$='performance_measure'] svg[data-icon='square-plus'],
div[data-rbd-draggable-id$='content_area'] svg[data-icon='square-plus'],
div[data-rbd-draggable-id$='evidence_source'] svg[data-icon='square-plus'],
div[data-rbd-draggable-id$='citations'] svg[data-icon='square-plus'],
div[data-rbd-draggable-id$='reference_notes'] svg[data-icon='square-plus'],
div[data-rbd-draggable-id$='script'] svg[data-icon='square-plus'],
div[data-rbd-draggable-id$='guidance'] svg[data-icon='square-plus'],
div[data-rbd-draggable-id$='finding_category'] svg[data-icon='square-plus'],
div[data-rbd-draggable-id$='review_type'] svg[data-icon='square-plus'],
div[data-rbd-draggable-id$='fiscal_year'] svg[data-icon='square-plus'],
div[data-rbd-draggable-id$='program_type'] svg[data-icon='square-plus'],
div[data-rbd-draggable-id$='accordion_title'] svg[data-icon='square-plus'],
div[data-rbd-draggable-id$='program_option'] svg[data-icon='square-plus'] {
  display: none !important;
}

div[data-rbd-draggable-id$='order'] label,
div[data-rbd-draggable-id$='tags'] label,
div[data-rbd-draggable-id$='performance_area'] label,
div[data-rbd-draggable-id$='performance_measure'] label,
div[data-rbd-draggable-id$='content_area'] label,
div[data-rbd-draggable-id$='evidence_source'] label,
div[data-rbd-draggable-id$='citations'] label,
div[data-rbd-draggable-id$='script'] label,
div[data-rbd-draggable-id$='reference_notes'] label,
div[data-rbd-draggable-id$='guidance'] label,
div[data-rbd-draggable-id$='finding_category'] label,
div[data-rbd-draggable-id$='review_type'] label,
div[data-rbd-draggable-id$='fiscal_year'] label,
div[data-rbd-draggable-id$='program_type'] label,
div[data-rbd-draggable-id$='accordion_title'] label,
div[data-rbd-draggable-id$='program_option'] label {
  font-family: inherit !important;
  color: #333 !important;
  font-weight: 600 !important;
  float: left;
  font-size: 14px;
}

.tags-label {
  overflow: hidden;
}

.tags-label {
  text-align: left;
  color: #ddd;
}

.help-text {
  font-size: 90%;
  color: #ababab;
  text-align: left;
}

#question-template {
  margin-bottom: 20px;
}

.add-details .popover .popover-inner {
  border: none;
}

.add-details .popover .popover-inner .popover-header {
  background-color: #fafafa !important;
  padding: 5px 15px !important;
  margin-bottom: 0px;
}

.add-details .popover .popover-inner .popover-body {
  padding: 5px 15px 10px;
}
.citations-form-field,
.citations {
  display: inline-block;
}
.citation-url {
  display: inline-block;
  margin-left: 5px;
}

.ec-document-assessed {
  background-color: #dadada !important;
  border: 1px solid #c2c2c2 !important;
  width: 100%;
  display: flex;
  padding: 1em;
  text-align: left;
  flex-direction: column;
}

.ec-document-assessed h3 {
  font-size: 16px;
  color: #333 !important;
  font-weight: 600 !important;
  margin-bottom: 5px;
}

.es-item lable {
  font-weight: bold;
  padding-right: 5px;
}

.es-item span {
  font-style: italic;
}

.guidance-form-field,
.guidance {
  display: block;
}

.review-script {
  border-bottom: 1px solid #ccc;
}

label[for$='tq_answer'].control-label,
label[for$='isAIANProgram'].control-label,
label[for$='isfileReviewed'].control-label {
  font-size: 16px;
  display: block;
}

label[for$='isAIANProgram'].control-label {
  margin-top: 40px;
}

div[id$='isAIANProgram'].field-radio-group {
  padding-bottom: 20px !important;
}

p[id$='tq_answer__description'].field-description {
  display: none;
}

p[id$='textbox__description'].field-description {
  display: none;
}

p.tq-answer-description {
  display: inline;
}

#form-builder-performanceMeasure .text {
  white-space: normal !important;
}

.contingent-checkboxes {
  padding-left: 40px;
  padding-bottom: 1px;
}

.reference-notes {
  padding: 10px 0px 0px;
}

.contingent-list {
  background-color: #bfbfbf !important;
  border: 1px solid #b3b3b3 !important;
  padding: 15px;
  margin-bottom: 15px;
}

.outcome-list {
  margin-right: 1em;
}

.responsive-item-check .checkbox-overlay {
  line-height: 5px;
}

.survey-question-section {
  margin-top: 20px;
}

.survey-question-section .survey-question-header {
  background-color: #dcdcdc !important;
  margin-top: 20px !important;
  color: rgba(0, 0, 0, 0.95);
  margin-bottom: 10px !important;
}

.btn-outline {
  box-shadow: 0 0 0 2px #245e9d inset !important;
  color: #245e9d !important;
  background-color: #fff !important;
}

.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
  background-color: #245e9d !important;
  color: #fff !important;
}
